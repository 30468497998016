import React from 'react'

function Header(){

return(

    <header className="App-header">
        <div className="title">
        <h1> 
            <span className="titleTop"><span>Designer</span></span><br/><span className="and"><span>&</span></span><br/><span className="and"><span>&</span></span><br/><span className="and"><span>&</span></span><br/><span className="and"><span>&</span></span><br/><span className="titleBottom"><span>Developer</span></span>
        <img src={require('../Foto/meAlt.jpg')} alt="" className='portrait'/>
        </h1>   
        
        </div>
        <div className="description">
            <p className="descriptionInner">
                
                <span className="myName">Alec Caley</span> was born in Tasmania at the tender age of zero.<br/><br/> 
            He became interested in technology at the age of 5 using his stepfather's MSDOS computer.<br/><br/> 
            At 12 he was making flash animations on his family laptop, and hogging the phone line for the dial-up.<br/><br/> 
            At 15 he completed his first game, and built his second computer.<br/><br/>
            He now lives in Sydney, developing websites from his laptop, and bouldering in his spare time.</p>
        </div>
    </header>
    )
}

export default Header
