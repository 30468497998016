import React, {Component} from 'react'

class Resume extends Component {

    constructor(props){
        super(props)
        this.state = {}
    }

    componentDidMount() {
        // pause videos and set play on hover / pause on unhover
        // Array.from(document.getElementsByTagName('video')).forEach(function(vid){
        //     vid.addEventListener('mouseover', function(){
        //         vid.play();
        //     })
        //     vid.addEventListener('mouseout', function(){
        //         vid.pause();
        //     })
        // })
    }

    render(){
            
        let ResumeCSS = {
            margin: '0 auto',
            textAlign: 'left',
            padding: '0 20px',
            paddingBottom: '100px'
        }
    
        let roleCss = {
            float: 'right'
        }
    
        let expSectionCss = {
            margin: '60px auto'
        }

        // https://i.imgur.com/2aouutZ.mp4 - alt SafetyCulture video

        let Roles = [
            {
                company: 'SafetyCulture',
                title: 'Software Engineer',
                dateRange: 'Oct 20 – Mar 23',
                description: 'I led the development of a user-facing website, boosting our conversion rate by 57% through A/B testing, which resulted in over 21,000 sign-ups per year. By designing end-to-end testing and enhancing our CI/CD pipeline, I reduced monthly incidents to effectively zero. Collaborating with our designer, we revamped the homepage and product pages, adding new features at pace. After being promoted into engineering, I added core features to our main app, built internal tools, and extended our API, working across a total of seven repositories, using React, TypeScript, Go, and more. All the while drinking from the firehose of knowledge in this new department.',
                video: 'https://i.imgur.com/fVF84sm.mp4',
                videoDesc: 'Template upload feature for the web-app greatly increased upload rate 🎉',
                link: 'https://safetyculture.com/'
            },
            {
                company: 'Bailey Nelson',
                title: 'UX, Lead Frontend Developer',
                dateRange: 'Mar 19 – Mar 20',
                description: 'During my time with BN I pushed for and successfully integrated A/B testing and critical reflection of Google Analytics data into their design iteration process. I advised on, designed and developed a rehaul of the user-flow, implementing a robust contact- lense subscription service, which included uniquely enabling in-store staff to grant customers personalised pre-filled checkout-flows - a first in the Optical industry. Over this period we saw an increase of over 80% online profit across multiple regions.',
                video: 'https://i.imgur.com/AEKCYjE.mp4',
                videoDesc: 'A promotion to encourage people to look after often overlooked organs – the eyes 👁️',
                link: 'https://baileynelson.com/pages/let-your-eyes-live'
            },
            {
                company: 'Aurora Transformations',
                title: 'UX, Full Stack Developer',
                dateRange: 'Sep 18 — Dec 18',
                description: 'I designed and built a modern, responsive website from the ground up. This was a great excercise in UX design. Proudest feature: a user-flow aware pre-filling form and custom coded page-transition animations to create a feeling when navigating the site distinct from the typical web experience.',
                video: 'https://i.imgur.com/wLvI0s8.mp4',
                videoDesc: 'Uniquely designed and coded with a React and jQuery 🪷',
                link: 'https://www.auroratransformations.com.au/'
            },
            {
                company: 'PaintAccess',
                title: 'UX, Frontend Developer',
                dateRange: 'Jan 18 — Aug 18',
                description: 'I worked with a leading online paint supplier to rehaul the mobile version of their Shopify site. While also making custom front/backend additions and maintaining the site on an ongoing basis. I even had the unusual and privileged opportunity to overlook the warehouse operations for a week while the previous manager was on emergency paternal leave.',
                video: 'https://i.imgur.com/2U1svLU.mp4',
                videoDesc: 'Custom Mobile frontend for Australia\'s leading online paint supplier',
                link: 'https://paintaccess.com.au'
            },
            {
                company: 'Substrate Digital',
                title: 'UX / Frontend Developer',
                dateRange: 'Jun 17 — Jan 18',
                description: 'I Worked on the design and prototyping of an upcoming rideshare app, contributing UX-efficiency improvements and developing a prototype to showcase the app to the client. Through wireframing and prototyping for multiple large-scale projects I became proficient in both Sketch and InVision. While also developing Wordpress sites with PHP, HTML, and CSS',
                video: '',
                link: ''
            },
            {
                company: 'NICTA',
                title: 'IT assistant, Work Experience',
                dateRange: 'Feb 11 — Mar 11',
                description: 'In highschool for work experience I applied to Nicta without really expecting much, to my absolute joy they granted me and my good friend the chance to help them out, both nerdy enough to be super excited by the opportunity we made our way to Redfern every day for a week and assisted in disassembling, modifying, reassembling, and ultimately installing a series of routers throughout the office. The engineer looking over us was extremely pleased to hear that we could handle it without much help needed as we\'d both built PCs in our spare time.',
                video: '',
                link: ''
            },
            {
                company: 'Don\'t todo app',
                title: 'Fun project',
                dateRange: '',
                description: 'A fun little don\'t do app I made to learn Express and Node.js',
                video: 'https://i.imgur.com/WKBHDXk.mp4',
                link: 'https://dont-todo.glitch.me/'
            }
        ]


        let resumeRaster = Roles.map(function(val, index, array){
            // let buttonId = 'showMore' + index
            // let infoId = 'moreInfo' + index
    
            return (
                <span key={index}>
                    <div className="experienceSection" style={expSectionCss}>
                        <h4 className="experienceTitle">{val.company} <span style={roleCss}>{val.title}</span></h4>
                        <p className="experienceDescription">
                            {val.description}
                        </p>
                        {/* <button className={buttonId} onClick={() => }>Show more v</button> */}
                        { val.video !== '' 
                        ?
                        <div className='videoContainer'>
                            
                        { val.link !== '' 
                            ?
                                <span className="innerVideoContainer">
                                    {/* Hiding links because some are broken */}
                                    {/* <span className="linkContainer"><a className="experienceLink clickable" href={val.link} target="_blank" rel="noopener noreferrer">see it live ⇗</a></span> */}
                                    <video autoPlay={true} muted loop>
                                        <source src={val.video} type="video/mp4" />
                                    </video >
                                </span>
                            : null
                        }
                        <p className='videoCaption' >{val.videoDesc}</p>
                        </div>
                        :
                        null
                        }
    

    
                    </div>
                </span>
            )
        })
        return(
            <div className='experienceOuter' style={ResumeCSS}>
                <h2 className='sectionTitle'>
                  Experience
                </h2>
                {resumeRaster}
                
            </div>
        )
    }
}

export default Resume