import React, {Component} from 'react'

var available = false; 
var availableCSS = {}
if(available){
    availableCSS = {
    background: '#5cff1d'
    }
} else {
    availableCSS = {
        background: '#b11616',
    }
}

class magicHeader extends Component {
    constructor(props){
        super()
        this.state = {
            opacity: 0,
            pe: 'none',
            copyPopupNo: 0,
            contactMenuOpen: false,
            scrollPos: 0,
        }
        this.copyToClipboard = this.copyToClipboard.bind(this);
        this.isVisible = this.isVisible.bind(this);
    }
    componentDidMount(){
        window.addEventListener('scroll', this.isVisible, true);
    }
    componentWillUnmount(){
        window.removeEventListener('scroll', this.isVisible);
    }

    isVisible = () => {
        var headerHeight = document.getElementsByClassName('App-header')[0].scrollHeight;
        var scrollPos = window.scrollY;
        var headerPassed = scrollPos > headerHeight ? true : false;
        if(headerPassed){
            this.setState({ opacity: 1, pe: 'auto'});
        } else {
            this.setState({ opacity: 0, pe: 'none' });
        }
    }

    copyToClipboard(val, e) {

        // Create an auxiliary hidden input
        var aux = document.createElement("input");
      
        // Get the text from the element passed into the input
        aux.setAttribute("value", val);
      
        // Append the aux input to the body
        document.body.appendChild(aux);
      
        // Highlight the content
        aux.select();
      
        // Execute the copy command
        document.execCommand("copy");
      
        // Remove the input from the body
        document.body.removeChild(aux);


        var tooltip = document.createElement('div');
        var tooltipIdentifier = 'tooltipNo' + this.state.copyPopupNo
        this.setState((prevState) => { return {copyPopupNo: prevState.copyPopupNo + 1}})

        var domString = '<div id='+tooltipIdentifier+' class="tooltipPopup">Copied!</div>';
        tooltip.innerHTML = domString;
        document.getElementById('root').appendChild(tooltip.firstChild);

        console.log(this.state.copyPopupNo)
        console.log(tooltipIdentifier)

        var positionX = e.pageX + 'px'
        var positionY = e.pageY + 'px'

        document.getElementById(tooltipIdentifier).style.left = positionX;
        document.getElementById(tooltipIdentifier).style.top = positionY;

        setTimeout(function(){ document.getElementById(tooltipIdentifier).remove()}, 3000 )
      
    }

    openContactMenu(e){
        if(this.state.openContactMenu){
            // re-enable background scrolling
            document.body.style.position = '';
            window.scrollTo({
                top: this.state.scrollPos,
                left: 0
            })

            document.getElementsByClassName('contactMenu')[0].style.opacity = 0;
            document.getElementsByClassName('contactMenu')[0].style.pointerEvents = 'none';
            this.setState({openContactMenu: false})

        } else {
            document.getElementsByClassName('contactMenu')[0].style.opacity = 1;
            document.getElementsByClassName('contactMenu')[0].style.pointerEvents = 'auto';
            // disable background scrolling
            this.setState({openContactMenu: true, scrollPos: window.scrollY})
            setTimeout(()=>{
                document.body.style.position = 'fixed';
            }, 420)
        }
    }

    openContactMenuAlt(e){
        if(this.state.openContactMenu){
            document.getElementsByClassName('App')[0].style.transform = 'translateX(0%)';

            this.setState({openContactMenu: false})
        } else {
            document.getElementsByClassName('App')[0].style.transform = 'translateX(-100%)';
            this.setState({openContactMenu: true})
        }
    }

    render(){
        return(
            <>
            <div className="magicHeader" style={{opacity: `${this.state.opacity}`, pointerEvents: `${this.state.pe}` } }>
        <p className='navButton' >{available ? 'Available' : 'Unavailable'} for freelance <span className='available' style={availableCSS}></span></p>
                <p className='navButton clickable' onClick={(e) => this.openContactMenu(e)} >Contact me ⇙</p>
            </div>
            <div className="contactMenu">
                    <p className='navButton close clickable' onClick={(e) => this.openContactMenu(e)} >X</p>
                    <p className="navButton clickable" onClick={(e) => this.copyToClipboard('aleccaley@gmail.com', e)}>aleccaley@gmail.com</p>
                    <p className="navButton clickable" onClick={(e) => this.copyToClipboard('0406388723', e)}>0406388723</p>
                    <div className="socialLogoContainer">
                        <a className="socialLogo clickable" target="_blank" rel="noopener noreferrer" href="https://www.linkedin.com/in/aleccaley/"><img src={require('../Foto/LI-In-Bug.png')} alt="Linked In Logo"/></a>
                    </div>
            </div>
            </>
        )
    }
}

export default magicHeader