import React from 'react';
import Header from './Components/Header'
import MovingHeader from './Components/MovingHeader'
import Resume from './Components/Resume'
import Footer from './Components/Footer'
import './App.css';
import './Mobile.css';
import './Fonts.css';

function App() {
  return (
    <div className="App">
      <MovingHeader/>
      <Header />
      <Resume />
      <Footer />
    </div>
  );
}

export default App;
