import React from 'react'

let footerCss = {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    fontSize: '14px',
    background: 'var(--nav-bg)',
    color: 'var(--primary-colour)'
}

function Footer() {

    return(
        <div className="footer" style={footerCss}>
            <p><span role="img" aria-label="heart">🧡</span>Made with love</p>
            <p>Built with React<span role="img" aria-label="test tube">🧪</span> </p>
        </div>
    )
}

export default Footer